import { useState, useEffect, useContext } from "react";
import { Toaster } from "react-hot-toast";

import { runAction } from "modules/utils";
import { PortalContext } from "contexts/PortalManager";

import Logo from "assets/brand/logo-full.png";
import SmallLogo from "assets/brand/logo-sidebar.png";

import { PermissionsProvider } from "contexts/PermissionsManager";
import { AsideProvider } from "contexts/AsideManager";
import { ToolbarProvider } from "contexts/ToolbarManager";

import Navbar from "components/system/Navbar";
import Sidebar from "components/system/Sidebar";
import tokenService from "services/token/token.service";

const NAV_HEIGHT = 54;
const SUB_NAV_HEIGHT = 42;
const TOTAL_NAV = NAV_HEIGHT + SUB_NAV_HEIGHT;

const DashboardLayout = ({
	children,
	page = "web",
	pages = [],
	externalMode = false,
}) => {
	const { callAction } = useContext(PortalContext);
	const [sidebarToggled, setSidebarToggled] = useState(false);
	const [isOnboarding, setIsOnboarding] = useState(null);
	// const { toggleSidebar } = useProSidebar();

	useEffect(() => {
		if (window.location.pathname.startsWith("/dev") || externalMode) {
			setIsOnboarding(false);
			return;
		}

		const settings = tokenService.getSettings();
		if (settings?.onboardingDone || tokenService.hasTemporaryAdmin()) {
			setIsOnboarding(false);
			//This is a gate to hijack the onboarding process, we should think for a better solution. Let us leave it for now.
		} else {
			setIsOnboarding(true);

			setTimeout(() => {
				runAction("auth", "openOnboarding", {
					callAction,
					callback: () => setIsOnboarding(false),
				});
			}, 400);
		}
	}, []);

	return (
		<PermissionsProvider>
			<div className="flex flex-col h-screen">
				<Navbar
					Logo={SmallLogo}
					toggleSidebar={() => setSidebarToggled((t) => !t)}
					pages={pages}
					externalMode={externalMode}
				/>
				<div
					className="dashboard flex flex-row"
					style={{ height: `calc(100vh - ${NAV_HEIGHT}px` }}
				>
					{!externalMode && (
						<Sidebar
							Logo={SmallLogo}
							page={page}
							toggled={sidebarToggled}
							setToggled={(t) => setSidebarToggled(t)}
						/>
					)}
					<div className="w-full max-h-screen bg-stone-100">
						<ToolbarProvider>
							<AsideProvider offset={SUB_NAV_HEIGHT}>
								{isOnboarding === null ||
								isOnboarding === true ? (
									<></>
								) : (
									children
								)}
							</AsideProvider>
						</ToolbarProvider>
					</div>
					<Toaster position="top-right" />
				</div>
			</div>
		</PermissionsProvider>
	);
};

export default DashboardLayout;
