import { Outlet } from 'react-router-dom';
import { withDashboardLayout } from '../../hoc';

const CoursesModule = () => {
	return <Outlet />;
};

const config = {
	page: 'assets',
	pages: [
		{ id: 'assets', label: 'assets', to: '' },
		// { id: "assets/audit", label: "Audit", to: "audit" },
		{ id: 'assets/settings', label: 'Settings', to: 'settings' },
	],
};

export default withDashboardLayout(CoursesModule, config);
