import { Outlet } from 'react-router-dom';
import { withDashboardLayout } from '../../hoc';

const CoursesModule = () => {
	return <Outlet />;
};

const config = {
	page: 'persons',
	pages: [
		{ id: 'persons/users', label: 'users', to: 'users' },
		{ id: 'persons/patients', label: 'patients', to: 'patients' },
	],
};

export default withDashboardLayout(CoursesModule, config);
