import {
	AddressSelector,
	CheckBox,
	CheckGroup,
	DateTimeInput,
	Input,
	Label,
	MultipleSelect,
	Radio,
	ReSelect,
	Select,
	TextArea,
} from 'components/ui/Input';
import PropTypes from 'prop-types';
import components from './components';
import { convertOptions, getDefaultValue } from './helper';
import getService from './serviceMapper';

const inputs = {
	Input: Input,
	TextArea: TextArea,
	Select: Select,
	ReSelect: ReSelect,
	Radio: Radio,
	Label: Label,
	Address: AddressSelector,
	MultipleSelect: MultipleSelect,
	Checkbox: CheckBox,
	CheckGroup: CheckGroup,
	DateTimeInput: DateTimeInput,
};

const InputField = ({
	name,
	inputType,
	defaultData = null,
	isRequired = false,
	isDisabled = false,
	options = [],
	label = '',
	parentId = null,
	isView = false,
	...args
}) => {
	const { component, type, isMultiple, serviceName, extraParams } = components.find((c) => c.id === inputType).value;
	const service = serviceName ? getService(serviceName, parentId) : null;
	const Component = inputs[component];

	return (
		<Component
			{...args}
			{...extraParams}
			isView={isView}
			placeholder={label}
			label={label}
			labelClassName={'block sm:hidden'}
			name={name}
			required={isRequired}
			disabled={isDisabled}
			type={type}
			service={service}
			defaultValue={getDefaultValue(defaultData, isMultiple)}
			options={options ? convertOptions(options) : []}
		/>
	);
};

InputField.propTypes = {
	name: PropTypes.string.isRequired,
	inputType: PropTypes.string.isRequired,
	defaultData: PropTypes.object,
	isRequired: PropTypes.bool,
	isDisabled: PropTypes.bool,
	options: PropTypes.array,
	parentId: PropTypes.number,
};

export default InputField;
