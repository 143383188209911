import { Form, FormEntry, InternalFormDivider } from 'components/form';
import { ColorPicker, Input, ReSelect } from 'components/ui/Input';
import { Loading } from 'components/ui/Interactive';
import { customIcons } from 'config';
import { useAside, useCrud, useTranslations } from 'hooks';
import TaskGroupService from 'modules/tasks/pages/Manage/services';
import { createRef, forwardRef, Suspense, useEffect, useImperativeHandle, useRef, useState } from 'react';
import DuplicateTaskTemplateButton from '../components/DuplicateTaskTemplateButton';
import Field from '../components/Field';
import FieldControl from '../components/FieldControl';
import FieldHelper from '../utils/FieldHelper';
import CustomFieldForm from './customField.form';
import PreviewForm from './preview.form';

const TaskTemplateForm = forwardRef(({ isView = false, ...props }, ref) => {
	const { getOne } = useCrud(props.service);
	const { translate } = useTranslations();
	const [data, setData] = useState([]);
	const myForm = useRef(null);
	const [loading, setLoading] = useState(true);
	const taskGroupService = new TaskGroupService();
	const [taskGroup, setTaskGroup] = useState(null);
	const [fields, setFields] = useState([]);
	const [movedIndex, setMovedIndex] = useState(null);
	const rowRefs = fields?.map(() => createRef());
	const { helperAsideBuilder } = useAside();
	const fieldHelper = FieldHelper({ fields });
	const [previewIsOpen, setPreviewIsOpen] = useState(false);

	const initiateData = async () => {
		if (props.data.id) {
			getOne(props.data.id).then((res) => {
				setData(res);
				setLoading(false);

				//if taskGroup field is hidden, then unset the field from the res?.fields match with taskGroupFieldId
				const fields = res?.fields.filter((field) => {
					const taskGroupField = taskGroup?.fields?.find(
						(tgf) => tgf.taskGroupFieldId === field.taskGroupFieldId,
					);
					return !taskGroupField?.isHidden;
				});

				//set indexes according to the order of the taskGroup fields
				fields.sort((a, b) => a.order - b.order);

				setFields(fields);
			});
		} else {
			setLoading(false);
		}
	};

	const getTaskGroups = async () => {
		await taskGroupService.getOne(props.taskGroupId).then((res) => {
			if (res.succeeded) {
				setTaskGroup(res.data);

				setFields(
					res.data.fields
						.filter((field) => !field.isHidden)
						?.map((field, index) => ({
							...field,
							order: index,
						})),
				);
			}
		});
	};

	const reOrder = (field, toUp = true) => {
		fieldHelper.reOrder(field, toUp, (newFields, newIndex) => {
			setFields(newFields);
			setMovedIndex(newIndex);
		});
	};

	const addField = (field) => {
		fieldHelper.addField(field, (fieldObject) => {
			setFields((prevFields) => [...prevFields, fieldObject]);
		});
	};

	const removeField = (index) => {
		fieldHelper.removeField(index, (newFields) => {
			setFields(newFields);
		});
	};

	const duplicateField = (field) => {
		fieldHelper.duplicateField(field, (newField) => {
			setFields([...fields, newField]);
			setMovedIndex(fields.length);
		});
	};

	const openCustomFieldsDialog = () => {
		helperAsideBuilder.setTitle(translate('addCustomField'));
		helperAsideBuilder.setComponent(CustomFieldForm);
		helperAsideBuilder.setOpen(true);
		helperAsideBuilder.setComponentProps({
			taskGroupId: props.taskGroupId,
			chooseField: (field) => {
				helperAsideBuilder.setOpen(false);
				addField(field);
			},
		});
		helperAsideBuilder.build();
	};

	useEffect(() => {
		if (movedIndex != null && rowRefs[movedIndex]?.current) {
			rowRefs[movedIndex].current.scrollIntoView({
				behavior: 'smooth',
				block: 'center',
				inline: 'nearest',
			});
		}
	}, [movedIndex]);

	const PreviewDialogTriggerButton = forwardRef((props, ref) => {
		return (
			<button
				ref={ref}
				type='button'
				className='mr-3 hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 inline-flex items-center absolute top-4 right-3'
				onClick={props.onClick}
			>
				<i className={`ri-slideshow-3-line text-lg`}></i>
				<span className='pl-1'>{translate('preview')}</span>
			</button>
		);
	});

	const openPreviewDialog = () => {
		setPreviewIsOpen(true);
		helperAsideBuilder.setTitle(translate('preview'));
		helperAsideBuilder.setComponent(PreviewForm);
		helperAsideBuilder.setOpen(true);
		helperAsideBuilder.setComponentProps({
			fields: fields,
			taskGroupId: props.taskGroupId,
			helperIsOpen: setPreviewIsOpen,
		});
		helperAsideBuilder.build();
	};

	useEffect(() => {
		if (previewIsOpen) {
			helperAsideBuilder.setComponentProps({
				fields: fields,
				taskGroupId: props.taskGroupId,
				helperIsOpen: setPreviewIsOpen,
			});
		}
	}, [fields]);

	useEffect(() => {
		const fetchData = async () => {
			await getTaskGroups();
			await initiateData();
		};
		fetchData();
	}, []);

	const resetData = () => {
		myForm.current.reset();
		setData([]);
	};

	const getData = () => {
		if (myForm.current && myForm.current.checkValidity()) {
			const formData = new FormData(myForm.current);
			const formDataObject = Object.fromEntries(formData.entries());
			return formDataObject;
		} else if (myForm.current) {
			myForm.current.reportValidity();
			return false;
		}
	};

	useImperativeHandle(ref, () => ({
		getData: () => getData(),
		clear: () => resetData(),
	}));

	return loading ? (
		<Loading status={loading} />
	) : (
		<Suspense fallback={<Loading status={true} />}>
			<PreviewDialogTriggerButton onClick={() => openPreviewDialog()} />
			<div className='w-full h-100 pb-10 overflow-y-visible'>
				<Form ref={myForm}>
					<input type='hidden' name='id' value={data?.id || false} />
					<InternalFormDivider>{translate('configuration')}</InternalFormDivider>
					<FormEntry label='templateName'>
						<Input
							isView={isView}
							type='text'
							minLength={3}
							placeholder={'templateName'}
							required={true}
							name='name'
							defaultValue={data?.name}
						/>
					</FormEntry>
					<FormEntry label='icon'>
						<ReSelect
							isView={isView}
							name='icon'
							required={true}
							options={customIcons.map((m) => ({
								value: m.path,
								label: translate(m.name),
								icon: m.path,
							}))}
							defaultValue={data?.icon}
						/>
					</FormEntry>
					<FormEntry label='color'>
						<ColorPicker isView={isView} name='color' value={data?.color} />
					</FormEntry>
					<InternalFormDivider>{translate('fields')}</InternalFormDivider>

					{fields?.map((field, index) => {
						return (
							<>
								<FieldControl
									isView={isView}
									ref={rowRefs[index]}
									key={`field-${index}`}
									field={field}
									index={index}
									orderUpVisible={index !== 0}
									orderUp={() => reOrder(field, true)}
									orderDownVisible={index < fields.length - 1}
									orderDown={() => reOrder(field, false)}
									duplicateField={() => duplicateField(field)}
									removeField={() => removeField(index)}
								/>

								<Field
									isView={isView}
									order={field?.order ?? index}
									isHidden={field?.isHidden ?? false}
									isDisabled={field?.isDisabled ?? false}
									isRequired={field?.isRequired ?? false}
									isAlwaysRequired={field?.isAlwaysRequired ?? false}
									isNeverRequired={field?.inputType === 8 ?? false}
									customFieldId={field?.customFieldId}
									taskGroupFieldId={field?.taskGroupFieldId}
									defaultValue={field?.defaultValue}
									id={field?.id ?? 0}
									name={field?.name}
									formKey={`fields[${index}]`}
									taskGroupId={props.taskGroupId}
									inputType={field?.inputType}
									options={field?.values ? JSON.parse(field?.values) : []}
									onFieldDataChange={(fieldData) => {
										const newFields = [...fields];
										newFields[index] = {
											...newFields[index],
											...fieldData,
										};
										setFields(newFields);
									}}
									key={`field-input-${field?.id}-${index}`}
								/>
							</>
						);
					})}
				</Form>
				{!isView && (
					<div
						onClick={() => openCustomFieldsDialog()}
						className='pt-3 pl-1 font-semibold cursor-pointer space-x-3'
					>
						<ri className='ri-add-line'></ri>
						<span>{translate('addField')}</span>
					</div>
				)}
			</div>
			{data?.id && !isView && (
				<div className=' left-16 bottom-6 absolute'>
					<DuplicateTaskTemplateButton {...props} />
				</div>
			)}
		</Suspense>
	);
});
export default TaskTemplateForm;
