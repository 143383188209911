import { useTranslations } from 'hooks';
import { runAction } from 'modules/utils';
import { useEffect, useState } from 'react';

const EnumLabelRender = ({ value, enumKey }) => {
	const { translate } = useTranslations();
	const [enumValues, setEnumValues] = useState(null); // null to indicate not yet loaded

	useEffect(() => {
		const getEnums = async () => {
			try {
				const l_enumValues = await runAction('tenants', 'getEnum', enumKey);
				setEnumValues(l_enumValues);
			} catch (error) {
				console.error('Failed to fetch enum values', error);
				setEnumValues({}); // fallback in case of error
			}
		};
		getEnums();
	}, [enumKey]);

	// Show loader if enumValues is null (loading state)
	if (!enumValues) {
		return <i className='ri-loader-4-line animate-spin text-blue-500 text-xl'></i>;
	}

	// Find the label corresponding to the enum value
	const labelVal = Object.keys(enumValues)
		.find((key) => enumValues[key] === value)
		?.replace(/([A-Z])/g, ' $1')
		.trim();

	return <span>{translate(labelVal)}</span>;
};

export default EnumLabelRender;
