import { withSuspense } from 'hoc';
import { useEffect, useMemo } from 'react';

import { useAside, useToolbar, useTranslations } from 'hooks';
import usePermissions, { PERMISSION, RESOURCE } from 'hooks/usePermissions';
import Swal from 'sweetalert2';
import { themeConfig } from 'utils';
import ImportForm from './forms/import.form';
import AssetsTable from './parts/AssetsTable';
import AssetService from './services';

const AssetsPage = () => {
	const { crumbsBuilder, actionsBuilder } = useToolbar();
	const { hasPermission } = usePermissions();
	const { asideBuilder } = useAside();
	const { translate } = useTranslations();

	const assetService = new AssetService();

	const permissionsMap = useMemo(
		() => ({
			assets: {
				Create: hasPermission(RESOURCE.Asset, PERMISSION.Create),
				View: hasPermission(RESOURCE.Asset, PERMISSION.View),
				Update: hasPermission(RESOURCE.Asset, PERMISSION.Create),
				Delete: hasPermission(RESOURCE.Asset, PERMISSION.Delete),
				Import: hasPermission(RESOURCE.Asset, PERMISSION.Import),
				Export: hasPermission(RESOURCE.Asset, PERMISSION.Export),
			},
		}),
		[hasPermission],
	);

	const handleImport = (file) => {
		console.log('Import should start now');
		Swal.fire({
			title: translate('youAreAboutToImportAssets'),
			showCancelButton: true,
			confirmButtonText: translate('confirm'),
			icon: 'warning',
			showLoaderOnConfirm: true,
			cancelButtonText: translate('cancel'),
			confirmButtonColor: themeConfig.color.primary,
			cancelButtonColor: '#b5bec9',
			preConfirm: async () => {
				asideBuilder.setOpen(false);
				asideBuilder.build();

				try {
					const res = await assetService.postImport(file);

					return {
						success: res?.succeeded,
						message: res?.message === 'ActionFailed' ? null : res.message,
					};
				} catch (e) {
					console.log('Error in import', e);
					return { success: false, message: null };
				}
			},
			allowOutsideClick: () => !Swal.isLoading(),
		}).then((result) => {
			if (result.value?.success) {
				Swal.fire({
					icon: 'success',
					title: translate('successfullyImported'),
					confirmButtonColor: themeConfig.color.primary,
					cancelButtonColor: '#b5bec9',
				}).then(() => window.location.reload());
			} else {
				Swal.fire({
					icon: 'error',
					title: translate('thereWasAProblemWihImportingThisFile'),
					html: `
							<div>
								<p>${result.value?.message || translate('pleaseCheckTheFileAndUseTheProvidedImportTemplate')}<p>
							</div>
						`,
					confirmButtonColor: themeConfig.color.primary,
				});
			}
		});
	};

	const openImport = () => {
		asideBuilder.setTitle(translate('newImport'));
		asideBuilder.setComponent(ImportForm);
		asideBuilder.setComponentKey(`new-import-${new Date()}`);
		asideBuilder.hideSaveButton(true);
		asideBuilder.setComponentProps({
			onImportStart: handleImport,
		});
		asideBuilder.setOpen(true);
		asideBuilder.build();
	};

	useEffect(() => {
		crumbsBuilder.addCurrent('assets');
		crumbsBuilder.build();

		if (permissionsMap.assets.Import) {
			const importAction = actionsBuilder.newAction('import', 'ri-contacts-book-upload-line');
			importAction.callback = openImport;
			actionsBuilder.addAction(importAction);
			actionsBuilder.build();
		}

		return () => {
			actionsBuilder.reset();
		};
	}, [permissionsMap]);

	return (
		<div className='grid grid-cols-1 gap-2'>
			<AssetsTable permissions={permissionsMap.assets} />
		</div>
	);
};

export default withSuspense(AssetsPage);
