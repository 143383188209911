import { Form, FormEntry, InternalFormDivider } from 'components/form';
import { CheckBox, Input, MultiAddressSelector, MultipleSelect, ReSelect, TextArea } from 'components/ui/Input';
import { Loading } from 'components/ui/Interactive';
import { useAside, useCrud, useTranslations } from 'hooks';
import CustomFieldForm from 'modules/tasks/pages/Manage/pages/TaskTemplates/pages/TaskTemplates/forms/customField.form';
import { forwardRef, Suspense, useEffect, useImperativeHandle, useRef, useState } from 'react';

import TaskTypeService from 'modules/tasks/pages/Manage/pages/TaskTypes/services';
import TaskGroupService from 'modules/tasks/pages/Manage/services';
import CustomFieldRenderer from '../components/CustomFieldRenderer';

const AdjustStaRuleForm = forwardRef(({ isView = false, ...props }, ref) => {
	const { translate } = useTranslations();
	const { helperAsideBuilder } = useAside();

	const { getOne } = useCrud(props.service);

	const [data, setData] = useState([]);
	const myForm = useRef(null);

	const [loading, setLoading] = useState(true);

	const [taskTypesOptions, setTaskTypesOptions] = useState([]);
	const [activeTaskGroup, setActiveTaskGroup] = useState(null);
	const [customFields, setCustomFields] = useState(null);

	const taskGroupService = new TaskGroupService();
	const taskTypeService = new TaskTypeService();

	const fetchTaskTypes = (taskGroupId = -1) => {
		taskTypeService.setParent(taskGroupId);
		taskTypeService.getOptionsList().then((res) => {
			setTaskTypesOptions(res?.data);
		});
	};

	const initiateData = () => {
		if (props.data.id) {
			getOne(props.data.id).then((res) => {
				const l_taskGroupId = res?.changeTaskTypeRule?.taskGroup?.id;
				setData(res);
				setLoading(false);

				setActiveTaskGroup(l_taskGroupId);
				fetchTaskTypes(l_taskGroupId);
				setCustomFields(res?.changeTaskTypeRule?.customFields || []);
			});
		} else {
			setLoading(false);
		}
	};

	useEffect(() => {
		initiateData();
	}, []);

	const getData = () => {
		if (myForm.current && myForm.current.checkValidity()) {
			const formData = new FormData(myForm.current);
			const formDataObject = Object.fromEntries(formData.entries());
			return formDataObject;
		} else if (myForm.current) {
			myForm.current.reportValidity();
		}
	};

	const resetData = () => {
		myForm.current.reset();
		setData([]);
	};

	useImperativeHandle(ref, () => ({
		getData: () => getData(),
		clear: () => resetData(),
	}));

	const openCustomFieldsDialog = () => {
		helperAsideBuilder.setTitle(translate('addCustomField'));
		helperAsideBuilder.setComponent(CustomFieldForm);
		helperAsideBuilder.setOpen(true);
		helperAsideBuilder.setComponentProps({
			taskGroupId: activeTaskGroup,
			chooseField: (field) => {
				helperAsideBuilder.setOpen(false);
				addField(field);
			},
		});
		helperAsideBuilder.build();
	};

	const addField = (field) => {
		setCustomFields((prev) => [...prev, field]);
	};

	useEffect(() => {
		helperAsideBuilder.setOpen(false);
		helperAsideBuilder.setComponentProps((prev) => ({
			...prev,
			taskGroupId: activeTaskGroup,
		}));

		if (activeTaskGroup !== data?.changeTaskTypeRule?.taskGroup?.id || !data.id) setCustomFields([]);
	}, [activeTaskGroup]);

	return loading ? (
		<Loading status={loading} />
	) : (
		<Suspense fallback={<Loading status={true} />}>
			<div className='w-full h-100 pb-10 overflow-y-visible'>
				<Form ref={myForm}>
					<input type='hidden' name='id' value={data?.id || false} />
					<input type='hidden' name='type' value={props.service.getTypeId()} />
					<FormEntry label='name'>
						<Input
							isView={isView}
							type='text'
							placeholder={'name'}
							required={true}
							name='name'
							defaultValue={data?.name}
						/>
					</FormEntry>
					<FormEntry label='description'>
						<TextArea
							isView={isView}
							placeholder={'description'}
							name='description'
							defaultValue={data?.description}
						/>
					</FormEntry>
					<FormEntry label={'enabled'}>
						<CheckBox isView={isView} name='enabled' label='enabled' selected={data?.enabled} />
					</FormEntry>
					<InternalFormDivider>{translate('configuration')}</InternalFormDivider>
					<FormEntry label='taskGroup'>
						<ReSelect
							isView={isView}
							extraParams='HideAllItem=true'
							name='changeTaskTypeRule.taskGroupId'
							defaultValue={data?.changeTaskTypeRule?.taskGroup}
							service={taskGroupService}
							onChange={(selected) => {
								setActiveTaskGroup(selected);
								fetchTaskTypes(selected);
							}}
						/>
					</FormEntry>
					<FormEntry label='taskTypes'>
						<MultipleSelect
							isView={isView}
							noAllAtSubmit={true}
							hasAllOption={false}
							name='changeTaskTypeRule.taskTypeIdsArray'
							options={taskTypesOptions}
							defaultValue={data?.changeTaskTypeRule?.taskTypeIds}
						/>
					</FormEntry>

					<FormEntry label='fromLocation'>
						<MultiAddressSelector
							isView={isView}
							name='changeTaskTypeRule.from'
							addresses={data?.changeTaskTypeRule?.from}
						/>
					</FormEntry>
					<FormEntry label='toLocation'>
						<MultiAddressSelector
							isView={isView}
							name='changeTaskTypeRule.to'
							addresses={data?.changeTaskTypeRule?.to}
						/>
					</FormEntry>

					<CustomFieldRenderer
						isView={isView}
						fields={customFields}
						taskGroupId={activeTaskGroup}
						onRemoveField={(field) => {
							setCustomFields((prev) => prev.filter((f) => f.id !== field.id));
						}}
					/>
					<FormEntry label='newTaskType'>
						<ReSelect
							isView={isView}
							required
							name='changeTaskTypeRule.newTaskTypeId'
							defaultValue={data?.changeTaskTypeRule?.newTaskType}
							options={taskTypesOptions}
						/>
					</FormEntry>
				</Form>
				{!isView && (
					<div
						onClick={() => activeTaskGroup && openCustomFieldsDialog()}
						className={`pt-3 pl-1 font-semibold cursor-pointer space-x-3
					${activeTaskGroup ? 'text-primary-500' : 'text-gray-400'}
					`}
					>
						{!activeTaskGroup && (
							<div className=' text-xs space-x-2 font-thin pl-3 pt-2 pb-3'>
								<i className='ri-information-line'></i>
								<span>{translate('inOrderToAddACustomFieldYouMustSelectAtLeastOneTaskGroup')} </span>
							</div>
						)}
						<ri className='ri-add-line'></ri>
						<span>{translate('addACustomField')}</span>
					</div>
				)}
			</div>
		</Suspense>
	);
});

export default AdjustStaRuleForm;
