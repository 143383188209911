import usePermissions, { PERMISSION, RESOURCE } from 'hooks/usePermissions';
import { useEffect } from 'react';

import { withSuspense } from 'hoc';
import { useToolbar } from 'hooks';
import PatientTable from './parts/PatientTable';
const Patients = () => {
	const { crumbsBuilder } = useToolbar();
	const { hasPermission } = usePermissions();

	useEffect(() => {
		crumbsBuilder.addPath('/persons/patients', 'patients');

		crumbsBuilder.build();
	}, []);

	const permissionMap = {
		Patients: {
			View: hasPermission(RESOURCE.Patient, PERMISSION.View),
			Create: hasPermission(RESOURCE.Patient, PERMISSION.Create),
			Update: hasPermission(RESOURCE.Patient, PERMISSION.Create),
			Delete: hasPermission(RESOURCE.Patient, PERMISSION.Delete),
		},
	};

	return (
		<div className='grid grid-cols-1 gap-2'>
			<PatientTable permissions={permissionMap.Patients} />
		</div>
	);
};

export default withSuspense(Patients);
