import HL7Settings from '../pages/HL7';
import TenantSettings from '../pages/Tenant';
import DispatcherSettings from '../pages/Tenant/pages/Dispatcher';
import ReservedSettings from '../pages/Tenant/pages/Reserved';
import Urgencies from '../pages/Tenant/pages/Urgencies/parts/UrgencyTable';

import PorterConfig from '../pages/PorterConfig';

const routes = [
	{
		header: 'general',
		key: 'General',
		items: [
			// {
			// 	name: "overview",
			// 	key: "Overview",
			// 	hash: "#overview",
			// 	component: Overview,
			// },
			{
				name: 'tenantSettings',
				key: 'TenantSettings',
				hash: '#tenantSettings',
				component: TenantSettings,
			},
			{
				name: 'reservedSettings',
				key: 'ReservedSettings',
				hash: '#reservedSettings',
				component: ReservedSettings,
			},
			{
				name: 'hl7Settings',
				key: 'HL7Settings',
				hash: '#hl7Settings',
				component: HL7Settings,
			},
			{
				name: 'dispatcherSettings',
				key: 'DispatcherSettings',
				hash: '#dispatcherSettings',
				component: DispatcherSettings,
			},
			{
				name: 'urgencies',
				key: 'Urgencies',
				hash: '#urgencies',
				component: Urgencies,
			},

			{
				name: 'porterConfig',
				key: 'PorterConfig',
				hash: '#porterConfig',
				component: PorterConfig,
			},
		],
	},
];

export default routes;
