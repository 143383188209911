import api from "services/api";
import tokenService from "services/token/token.service";

const endpoint = "generalcontent/types";

const generalContentTypes = {
	Gender: 1,
	UserTitle: 2,
	TeamRole: 3,
	RoomType: 4,
};

export const getTeamRoleOptions = async () => {
	return await api.get(
		`${endpoint}/${generalContentTypes.TeamRole}/options`,
		{
			headers: {
				Authorization: `Bearer ${tokenService.getLocalAccessToken()}`,
			},
		}
	);
};

export const getGenderOptions = async () => {
	const data = await api.get(
		`${endpoint}/${generalContentTypes.Gender}/options`,
		{
			headers: {
				Authorization: `Bearer ${tokenService.getLocalAccessToken()}`,
			},
		}
	);
	return data?.data ?? null;
};
