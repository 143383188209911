import AbstractService from "services/Service";

const endpoint = "users/active-directory/mappings";
const syncEndpoint = "users/active-directory/sync";

class Service extends AbstractService {
	constructor() {
		super();
		this.endpoint = endpoint;
	}

	async synchronize() {
		return await this.api.get(syncEndpoint);
	}

	mapData(data) {
		const { id, userProfileIds, blockNormalLogin, ...rest } = data;
		return {
			...rest,
			userProfileIds: userProfileIds
				? userProfileIds.split(",").map(Number)
				: [],
			blockNormalLogin: blockNormalLogin === "true",
		};
	}
}
export default Service;
