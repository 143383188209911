import addressesUtils from './addresses/commonUtils';
import authUtils from './auth/commonUtils';
import TaskModuleUtils from './global/modules/taskModules/utils';
import translationUtils from './global/modules/translations/utils';
import { getGenderOptions, getTeamRoleOptions } from './global/services/generalContents';
import { getCountryByLanguageCode, getCountryFlag, getLanguages } from './global/services/languages';
import { getPermissions } from './persons/pages/Users/pages/Settings/pages/PermissionGroups/utils';
import scannerUtils from './scanners/commonUtils';
import TenantUtils from './settings/pages/generalSettings/pages/Tenant/utils';

const utils = {
	auth: { ...authUtils },
	language: { getLanguages, getCountryByLanguageCode, getCountryFlag }, //Should be removed
	addresses: { ...addressesUtils },
	tenants: TenantUtils,
	generalContents: { getTeamRoleOptions, getGenderOptions }, //TODO: This should be stored in localStorage too. Fix this later.,
	permissions: { getPermissions },
	translations: { ...translationUtils },
	taskModules: TaskModuleUtils,
	scanners: { ...scannerUtils },
};

// Simplified Mediator Pattern
export const runAction = (module, event, data) => {
	// console.log(`Requestion action ${module}:${event}`); //let us uncomment this one, it will be useful for debugging
	if (!(module in utils)) {
		console.log(`Requesting module ${module} does not exist`);
		return null;
	}

	const actions = utils[module];
	if (!(event in actions)) {
		console.log(`Requesting ${event}@${module} does not exist`);
		return null;
	}

	const action = actions[event];
	return action(data);
};
