import { BadgedListColumn, IdColumn, SimpleObjectColumn } from 'core/columns';
import BaseModel from 'core/models/BaseModel';

class DefaultSettingModel extends BaseModel {
	constructor() {
		super();
		this.modelName = 'defaultSetting';
		this.pluralModelName = 'defaultSettings';
	}

	initializeColumns() {
		this.columns.push(new IdColumn());

		this.columns.push(new SimpleObjectColumn('taskGroup'));
		this.columns.push(new BadgedListColumn('taskTypes'));
		this.assignColumnOrder();
	}
	isExportable() {
		return false;
	}
	areSelectsVisible() {
		return false;
	}
}

export default DefaultSettingModel;
