import { Sidebar } from "react-pro-sidebar";
import { Link } from "react-router-dom";
import { useTranslations } from "hooks";
import { getSidebarConf } from "../../config/system";

const ModuleLink = ({ icon, label, to, active = false }) => {
	const { translate } = useTranslations();
	return (
		<Link to={to} className="w-full">
			<div
				className={`px-2 py-4  flex flex-col items-center group rounded`}
			>
				<div
					className={`${
						active
							? "bg-primary-100 text-primary-900"
							: "bg-slate-100 text-gray-500"
					} group-hover:bg-primary-50 h-10 w-10 rounded-full flex flex-col items-center justify-center mb-2`}
				>
					<i className={`${icon} text-xl group-hover:opacity-50`} />
				</div>
				<small
					className={`${
						active ? "font-bold" : ""
					} group-hover:opacity-50 text-center`}
				>
					{translate(label, true)}
				</small>
			</div>
		</Link>
	);
};

const SidebarComp = ({ Logo, page, toggled, setToggled = () => {} }) => {
	const SidebarConf = getSidebarConf();
	return (
		<Sidebar
			collapsedWidth="80px"
			className="main-sidebar z-40"
			backgroundColor="#ffffff"
			customBreakPoint="640px"
			collapsed={true}
			toggled={toggled}
			onBackdropClick={() => setToggled(false)}
		>
			<div className="flex max-h-screen flex-col items-center h-full px-2">
				<Link to="/" className="w-2/3 py-4 block sm:hidden">
					<img src={Logo} className="w-full" alt="Brand" />
				</Link>

				{SidebarConf.modules.map((module) => (
					<ModuleLink
						key={`module-link-${module.id}`}
						{...module}
						active={page === module.id}
					/>
				))}

				<div className="h-full flex flex-1"></div>
				{SidebarConf.settings.show && (
					<ModuleLink
						{...SidebarConf.settings}
						active={page === SidebarConf.settings.id}
					/>
				)}
			</div>
		</Sidebar>
	);
};

export default SidebarComp;
