import { withDashboardLayout } from "../../hoc";
import { Outlet } from "react-router-dom";

const CoursesModule = () => {
	return <Outlet />;
};

const config = {
	page: "tasks",
	pages: [
		{ id: "tasks", label: "tasks", to: "tasks" },
		{ id: "tasks/rules", label: "rules", to: "rules" },
		{ id: "tasks/manage", label: "manage", to: "manage" },
		{ id: "tasks/settings", label: "settings", to: "settings" },
	],
};

export default withDashboardLayout(CoursesModule, config);
