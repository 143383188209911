import React from "react";
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Link } from "react-router-dom";
import { useTranslations } from "hooks";

const MenuHeader = ({ children }) => {
	return <div className="text-md font-bold px-4 mt-6 mb-2">{children}</div>;
};

const SettingsSidebar = ({
	hash = "",
	toggled,
	setToggled = () => {},
	routes = [],
}) => {
	const { translate } = useTranslations();

	if (hash === "" || hash === null) {
		hash = routes[0]?.items[0]?.hash;
	}

	return (
		<Sidebar
			breakPoint="md"
			className="main-sidebar z-50"
			backgroundColor="#ffffff"
			customBreakPoint="640px"
			rootStyles={{
				// borderRight: "1px solid rgb(156 163 175)",
				height: "100%",
				zIndex: 20,
			}}
			// collapsed={false}
			toggled={toggled}
			onBackdropClick={() => setToggled(false)}
		>
			<Menu
				menuItemStyles={{
					button: ({ level, active, disabled }) => {
						// only apply styles on first level elements of the tree
						if (level === 0)
							return {
								color: active ? "#222222" : undefined,
								paddingLeft: 32,
								fontWeight: active ? "600" : undefined,
								"&:hover": {
									backgroundColor: "rgb(209 213 219)",
								},
								opacity: disabled ? "0.5" : 1,
							};
					},
				}}
			>
				<div className="h-5"></div>
				{routes?.map((section) => (
					<div key={section.header}>
						<MenuHeader>{translate(section.header)}</MenuHeader>
						{section?.items?.map((item) => (
							<MenuItem
								key={item.hash}
								component={<Link to={item.hash} />}
								active={hash === item.hash}
							>
								{translate(item.name)}
							</MenuItem>
						))}
					</div>
				))}
			</Menu>
		</Sidebar>
	);
};

export default SettingsSidebar;
