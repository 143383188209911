import { Form, FormEntry, InternalFormDivider } from 'components/form';
import { CheckBox, Input, MultipleSelect, ReSelect, TextArea } from 'components/ui/Input';
import { Loading } from 'components/ui/Interactive';
import { useCrud, useTranslations } from 'hooks';
import Department from 'modules/persons/pages/Users/pages/TeamsAndDepartments/Teams/components/Department';
import { forwardRef, Suspense, useEffect, useImperativeHandle, useRef, useState } from 'react';

import RoomTypeService from 'modules/addresses/pages/settings/pages/RoomType/services';
import DepartmentService from 'modules/persons/pages/Users/pages/TeamsAndDepartments/Departments/services';
import TeamService from 'modules/persons/pages/Users/pages/TeamsAndDepartments/Teams/services';
import TaskTypeService from 'modules/tasks/pages/Manage/pages/TaskTypes/services';
import TaskGroupService from 'modules/tasks/pages/Manage/services';

const GroupingTaskRuleForm = forwardRef(({ isView = false, ...props }, ref) => {
	const { translate } = useTranslations();

	const { getOne } = useCrud(props.service);

	const [data, setData] = useState([]);
	const myForm = useRef(null);

	const [loading, setLoading] = useState(true);

	const [taskTypesOptions, setTaskTypesOptions] = useState([]);
	const [roomTypeOptions, setRoomTypeOptions] = useState([]);
	const [teamOptions, setTeamOptions] = useState([]);
	const [departmentOptions, setDepartmentOptions] = useState([]);
	const [departmentIsvalid, setDepartmentIsValid] = useState(true);

	const taskGroupService = new TaskGroupService();
	const taskTypeService = new TaskTypeService();
	const roomTypeService = new RoomTypeService();
	const teamService = new TeamService();
	const departmentService = new DepartmentService();

	const fetchTaskTypes = (taskGroupId = -1) => {
		taskTypeService.setParent(taskGroupId);
		taskTypeService.getOptionsList().then((res) => {
			setTaskTypesOptions(res?.data);
		});
	};

	const fetchTeams = () => {
		teamService.getOptionsList().then((res) => {
			setTeamOptions(res?.data);
		});
	};

	const fetchRoomTypes = () => {
		roomTypeService.getOptionsList().then((res) => {
			setRoomTypeOptions(res?.data);
		});
	};

	const fetchDepartmentOptions = async () => {
		departmentService.getOptionsList().then((res) => {
			setDepartmentOptions(res?.data.filter((item) => item.value !== 1));
		});
	};

	const initiateData = () => {
		if (props.data.id) {
			getOne(props.data.id).then((res) => {
				const l_taskGroupId = res?.groupingTaskRule?.taskGroup?.id;
				setData(res);
				setLoading(false);

				fetchTaskTypes(l_taskGroupId);
			});
		} else {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchRoomTypes();
		fetchTeams();
		fetchDepartmentOptions();
		initiateData();
	}, []);

	const getData = () => {
		if (myForm.current && myForm.current.checkValidity()) {
			if (!departmentIsvalid) {
				return;
			}

			const formData = new FormData(myForm.current);
			const formDataObject = Object.fromEntries(formData.entries());
			return formDataObject;
		} else if (myForm.current) {
			myForm.current.reportValidity();
		}
	};

	const resetData = () => {
		myForm.current.reset();
		setData([]);
	};

	useImperativeHandle(ref, () => ({
		getData: () => getData(),
		clear: () => resetData(),
	}));

	return loading ? (
		<Loading status={loading} />
	) : (
		<Suspense fallback={<Loading status={true} />}>
			<div className='w-full h-100 pb-10 overflow-y-visible'>
				<Form ref={myForm}>
					<input type='hidden' name='id' value={data?.id || false} />
					<input type='hidden' name='type' value={props.service.getTypeId()} />
					<FormEntry label='name'>
						<Input
							isView={isView}
							type='text'
							placeholder={'name'}
							required={true}
							name='name'
							defaultValue={data?.name}
						/>
					</FormEntry>
					<FormEntry label='description'>
						<TextArea
							isView={isView}
							placeholder={'description'}
							name='description'
							defaultValue={data?.description}
						/>
					</FormEntry>
					<FormEntry label={'enabled'}>
						<CheckBox isView={isView} name='enabled' label='enabled' selected={data?.enabled} />
					</FormEntry>
					<InternalFormDivider>{translate('configuration')}</InternalFormDivider>
					<FormEntry label='allowedDepartmentWay'>
						<Department
							isView={isView}
							departments={data?.groupingTaskRule?.departments}
							options={departmentOptions}
							isValid={(status) => {
								setDepartmentIsValid(status);
							}}
						/>
					</FormEntry>
					<FormEntry label='roomTypes'>
						<MultipleSelect
							isView={isView}
							required={true}
							noAllAtSubmit={true}
							hasAllOption={true}
							name='groupingTaskRule.roomTypeIdsArray'
							options={roomTypeOptions}
							defaultValue={data?.groupingTaskRule?.roomTypeIds}
						/>
					</FormEntry>
					<FormEntry label='taskGroup'>
						<ReSelect
							isView={isView}
							extraParams='HideAllItem=true'
							required={true}
							name='groupingTaskRule.taskGroupId'
							defaultValue={data?.groupingTaskRule?.taskGroup}
							service={taskGroupService}
							onChange={(selected) => {
								fetchTaskTypes(selected);
							}}
						/>
					</FormEntry>
					<FormEntry label='taskTypes'>
						<MultipleSelect
							isView={isView}
							required={true}
							noAllAtSubmit={true}
							hasAllOption={false}
							name='groupingTaskRule.taskTypeIdsArray'
							options={taskTypesOptions}
							defaultValue={data?.groupingTaskRule?.taskTypeIds}
						/>
					</FormEntry>
					<FormEntry label='teams'>
						<MultipleSelect
							isView={isView}
							noAllAtSubmit={true}
							hasAllOption={false}
							required={true}
							name='groupingTaskRule.teamIdsArray'
							options={teamOptions}
							defaultValue={data?.groupingTaskRule?.teamIds}
						/>
					</FormEntry>
					<FormEntry label={'aroundMinutes'}>
						<Input
							isView={isView}
							placeholder={'aroundMinutes'}
							type='number'
							min={0}
							name='groupingTaskRule.aroundMinutes'
							defaultValue={data?.groupingTaskRule?.aroundMinutes}
							sideLabel={'mins'}
						/>
					</FormEntry>
					<FormEntry label={'maxTaskGroupedCount'}>
						<Input
							isView={isView}
							placeholder={'maxTaskGroupedCount'}
							type='number'
							min={0}
							name='groupingTaskRule.maxTaskGroupedCount'
							defaultValue={data?.groupingTaskRule?.maxTaskGroupedCount}
						/>
					</FormEntry>
					<FormEntry label={'regroupWithTaskInTransit'}>
						<CheckBox
							isView={isView}
							name='groupingTaskRule.regroupWithTaskInTransit'
							label='regroupWithTaskInTransit'
							selected={data?.groupingTaskRule?.regroupWithTaskInTransit}
						/>
					</FormEntry>
				</Form>
			</div>
		</Suspense>
	);
});

export default GroupingTaskRuleForm;
