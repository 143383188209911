import ADConfigurationTable from '../pages/ADConfiguration/parts/ADConfigurationTable';
import PermissionGroupsTable from '../pages/PermissionGroups/parts/PermissionGroupsTable';
import SetupView from '../pages/Setup';
const routes = [
	{
		header: 'general',
		key: 'General',
		items: [
			// {
			// 	name: "overview",
			// 	key: "Overview",
			// 	hash: "#overview",
			// 	component: Overview,
			// },
			// {
			// 	name: "lists",
			// 	key: "Lists",
			// 	hash: "#lists",
			// 	component: NotImplemented,
			// },
			{
				name: 'permissionGroups',
				key: 'PermissionGroup',
				hash: '#permission-groups',
				component: PermissionGroupsTable,
			},
		],
	},
	{
		header: 'SSO',
		key: 'SSO',
		items: [
			{
				name: 'setup',
				key: 'Setup',
				hash: '#ad-setup',
				component: SetupView,
			},

			{
				name: 'configuration',
				key: 'Configuration',
				hash: '#ad-config',
				component: ADConfigurationTable,
			},
		],
	},
];

export default routes;
