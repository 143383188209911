import { BadgedListRender } from 'core/renders';
import { useTranslations } from 'hooks';
import { forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { MultiSelect } from 'react-multi-select-component';
import ViewInput from './internal/ViewInput';

const MultipleSelect = forwardRef(
	(
		{
			name,
			options,
			value = null,
			onSelectOption = () => {},
			hasSelectAll = true,
			disableOnAll = true,
			allOptionValue = 1,
			valueKey = 'id',
			labelKey = 'name',
			defaultValue = null,
			disabled = false,
			required = false,
			hasAllOption = true,
			noAllAtSubmit = false,
			valueIsObject = null,
			containerClassName = '',
			placeholder = null,
			hideLabel = false,
			label = null,
			labelClassName = null,
			onChange = () => {},
			isView = false,
			defaultValue_ = null, //TODO: This must be replaced with defaultValue
			allItemsAreSelectedMessage = 'allItemsAreSelected',
			viewBadgeClassName = '',
		},
		ref,
	) => {
		const { translate } = useTranslations();
		const [optionValues, setOptionValues] = useState(options ?? []);
		const [inputInvalid, setInputInvalid] = useState(false);
		const inputRef = useRef(null);
		const hasMounted = useRef(false);

		useEffect(() => {
			if (!hasMounted.current && defaultValue_ && options?.length > 0) {
				setSelectedOptions(formatValues(defaultValue_));
				hasMounted.current = true;
			}
		}, [defaultValue_, options]);

		useEffect(() => {
			setSelectedOptions((prev) => {
				return prev.map((item) => {
					return {
						value: item.value,
						label: getNameFromOptions(item.value),
					};
				});
			});
		}, [options]);

		const values = defaultValue ?? value;

		const valuesAreOnlyId = useMemo(
			() =>
				valueIsObject !== true &&
				(Array.isArray(value) ? value.every((item) => typeof item === 'number') : true),
			[value, valueIsObject],
		);

		const getNameFromOptions = useCallback((val) => options.find((item) => item.value === val)?.label, [options]);

		const formatValues = useCallback(
			(l_values) => {
				if (!l_values || !options.length) return [];

				const selectedValues = l_values.filter((item) =>
					valuesAreOnlyId
						? options.some((opt) => opt.value === item)
						: options.some((opt) => opt[valueKey] === item[valueKey]),
				);

				if (hasAllOption && selectedValues.some((item) => item === allOptionValue)) {
					return options.filter((item) => item.value !== allOptionValue);
				}

				return selectedValues.map((item) =>
					valuesAreOnlyId
						? { value: item, label: getNameFromOptions(item) }
						: { value: item[valueKey], label: item[labelKey] },
				);
			},
			[options, valuesAreOnlyId, getNameFromOptions, valueKey, labelKey, allOptionValue, hasAllOption],
		);

		const [selectedOptions, setSelectedOptions] = useState(formatValues(values));

		useEffect(() => {
			setOptionValues(options);

			const values = defaultValue ?? value;

			if (values) {
				setSelectedOptions(formatValues(values));
			}
		}, [options, defaultValue, value]);

		function getSelectedOptionsValues() {
			//if all items are selected return only the allOptionValue

			if (!noAllAtSubmit && selectedOptions.length === optionValues.length - (hasAllOption ? 1 : 0)) {
				return [allOptionValue];
			} else {
				if (hasAllOption) {
					return selectedOptions.filter((item) => item.value !== allOptionValue).map((i) => i.value);
				} else {
					return selectedOptions.map((i) => i.value);
				}
			}
		}

		const customValueRenderer = (selected, _options) => {
			if (selected.length === _options.length) {
				return translate(allItemsAreSelectedMessage);
			}

			let labels = selected
				.map(({ label }) => label + ', ')
				.join('')
				.slice(0, -2);
			labels = labels.length > 35 ? labels.substring(0, 35) + '...' : labels;
			return selected.length ? labels : placeholder || `${translate('selectAnItem')}...`;
		};

		const checkValidity = () => {
			if (required && selectedOptions.length === 0) {
				setInputInvalid(true);
			} else {
				setInputInvalid(false);
			}
		};

		const selectOptionHandler = (l_selected) => {
			setSelectedOptions(l_selected);
			if (valuesAreOnlyId) {
				const selected = l_selected.map((item) => item.value);
				onSelectOption(selected);
			} else {
				onSelectOption(l_selected);
			}

			checkValidity();
			onChange(
				l_selected.map((item) => ({
					Id: item.value,
					Name: item.label,
				})),
			);
		};
		if (isView) {
			return (
				<ViewInput
					value={
						<BadgedListRender
							items={selectedOptions.map((item) => ({ ...item, name: item.label }))}
							tooltipContainerId='aside-tooltip'
							itemLengthToTruncate={32}
							displayMax={3}
							itemClassName={`w-80 ${viewBadgeClassName}`}
						/>
					}
				/>
			);
		}

		return (
			<div className={`relative w-full rounded-lg  ${containerClassName}`}>
				{!hideLabel && label && (
					<label className={`block mb-1 text-xs font-medium text-gray-700 ${labelClassName}`}>
						{translate(label || '', true)}
					</label>
				)}
				<div className={`border  ${inputInvalid ? ' border-red-500' : ' border-gray-300'}`}>
					<input
						ref={inputRef}
						type='text'
						className='sr-only'
						required={required}
						name={`${name}`}
						onChange={() => {}}
						value={getSelectedOptionsValues()}
					/>
					{/* //TODO: WE need some internaization within here */}
					<MultiSelect
						disabled={disabled}
						ref={ref}
						overrideStrings={{
							selectSomeItems: translate('selectSomeItems'),
							allItemsAreSelected: translate(allItemsAreSelectedMessage),
							selectAll: translate('selectAll'),
							search: translate('search'),
						}}
						valueRenderer={customValueRenderer}
						ArrowRenderer={() => <i className='ri-arrow-down-s-line'></i>}
						className='block rounded-lg  text-sm font-medium text-gray-900'
						options={
							optionValues
								? hasAllOption
									? optionValues?.filter((item) => item.value !== allOptionValue)
									: optionValues
								: null
						}
						onChange={(selected) => {
							selectOptionHandler(selected);
						}}
						value={selectedOptions || null}
						hasSelectAll={hasSelectAll}
					/>
				</div>
			</div>
		);
	},
);
export default MultipleSelect;
