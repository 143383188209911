import { CrudOperations, withPaddinglessLayout, withSuspense } from 'hoc';
import { useAside, useCrud, useToolbar, useTranslations } from 'hooks';
import usePermissions, { PERMISSION, RESOURCE } from 'hooks/usePermissions';
import TaskService from 'modules/tasks/pages/Tasks/pages/Tasks/services';
import { cloneElement, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useRoutes } from 'react-router-dom';
import Swal from 'sweetalert2';
import { themeConfig } from 'utils';
import LinkTabs from './components/LinkTabs';
import ToggleButton from './components/SidebarToggleButton';
import TaskGroupSidebar from './components/TaskGroupSidebar';
import ImportForm from './forms/Import.form';
import TaskGroupForm from './forms/Taskgroup.form';
import routes from './routes';
import TaskGroupService from './services';
// import CrudOperations from "./parts/CrudOperations";

const Manage = () => {
	const { crumbsBuilder, actionsBuilder } = useToolbar();
	const { hasPermission } = usePermissions();
	const { asideBuilder } = useAside();

	const service = new TaskGroupService();
	const taskService = new TaskService();
	const { getAll, create, update, remove } = useCrud(service);

	const location = useLocation();
	const navigate = useNavigate();
	const { translate } = useTranslations();
	const pathName = location.pathname; // Use this to dynamically render pages

	const [activeTaskGroup, setActiveTaskGroup] = useState(location.pathname.split('/')[3] || '');
	const [activeTaskGroupId, setActiveTaskGroupId] = useState(-1);

	useEffect(() => {
		const l_taskgroup = location.pathname.split('/')[3] || '';
		const l_taskgroupId = l_taskgroup.split('_')[1];

		setActiveTaskGroup(l_taskgroup);
		setActiveTaskGroupId(l_taskgroupId);
	}, [location]);

	const [data, setData] = useState([]);
	const [sidebarToggled, setSidebarToggled] = useState(false);

	const taskGroupPermissions = useMemo(
		() => ({
			Create: hasPermission(RESOURCE.TaskGroup, PERMISSION.Create),
			View: hasPermission(RESOURCE.TaskGroup, PERMISSION.View),
			Update: hasPermission(RESOURCE.TaskGroup, PERMISSION.Create),
			Delete: hasPermission(RESOURCE.TaskGroup, PERMISSION.Delete),
			Export: hasPermission(RESOURCE.TaskGroup, PERMISSION.Export),
			Import: hasPermission(RESOURCE.TaskGroup, PERMISSION.Import),
		}),
		[hasPermission],
	);

	const permissionsMap = {
		taskTemplates: {
			TaskTemplates: {
				Create: hasPermission(RESOURCE.Template, PERMISSION.Create),
				View: hasPermission(RESOURCE.Template, PERMISSION.View),
				Update: hasPermission(RESOURCE.Template, PERMISSION.Create),
				Delete: hasPermission(RESOURCE.Template, PERMISSION.Delete),
				Export: hasPermission(RESOURCE.Template, PERMISSION.Export),
			},
			Documentations: {
				Create: hasPermission(RESOURCE.Documentations, PERMISSION.Create),
				View: hasPermission(RESOURCE.Documentations, PERMISSION.View),
				Update: hasPermission(RESOURCE.Documentations, PERMISSION.Create),
				Delete: hasPermission(RESOURCE.Documentations, PERMISSION.Delete),
				Export: hasPermission(RESOURCE.Documentations, PERMISSION.Export),
			},
		},
		taskTypes: {
			TaskTypes: {
				Create: hasPermission(RESOURCE.TaskType, PERMISSION.Create),
				View: hasPermission(RESOURCE.TaskType, PERMISSION.View),
				Update: hasPermission(RESOURCE.TaskType, PERMISSION.Create),
				Delete: hasPermission(RESOURCE.TaskType, PERMISSION.Delete),
				Export: hasPermission(RESOURCE.TaskType, PERMISSION.Export),
			},
		},
	};
	let localRoutes = routes?.map((route) => {
		return {
			...route,
			path: activeTaskGroup !== '' ? route.path.replace('{taskgroup}', activeTaskGroup) : route.path,
		};
	});

	//we have to set as active item the first one if the activeTaskGroup is removed
	const fetchAndLoad = async (resetActiveTaskGroup = false) => {
		const res = await getAll();
		res.data?.forEach((item) => {
			item.hash = 'tg_' + item.id;
		});
		setData(res.data);

		if (resetActiveTaskGroup || activeTaskGroup === '') {
			let l_taskgroup = res.data[0]?.hash;
			if (l_taskgroup !== undefined) {
				const t_route = localRoutes[0]?.path.replace(
					resetActiveTaskGroup ? activeTaskGroup : '{taskgroup}',
					l_taskgroup,
				);

				navigate(t_route, { replace: true });
			} else {
				navigate('/tasks/manage', { replace: true });
			}
		}
	};

	const { openCreate, openEdit } = CrudOperations({
		create,
		update,
		remove,
		fetchAndLoad,
		service,
		form: TaskGroupForm,
		componentName: 'TaskGroup',
		permissions: taskGroupPermissions,
		fetchAndLoadParams: [true],
	});

	useEffect(() => {
		crumbsBuilder.addPath('/tasks', 'tasks');
		crumbsBuilder.addCurrent('manage');
		crumbsBuilder.build();

		fetchAndLoad();
	}, []);

	const getIdOfTaskGroup = () => {
		return activeTaskGroup.split('_')[1];
	};

	// let localRoutes = [...routes];

	const routing = useRoutes([
		...localRoutes?.map((item, index) => {
			let path = item.path;
			let parts = path.split('/');
			let newPath = '/' + parts[parts.length - 2] + '/' + parts[parts.length - 1];
			const permissions = permissionsMap[item.key];
			const elementWithPermissions = cloneElement(item.element, {
				permissions,
				taskGroupId: activeTaskGroupId,
			});

			return {
				...item,
				path: newPath,
				element: elementWithPermissions,
			};
		}),
	]);

	const handleImport = (file) => {
		Swal.fire({
			title: translate('youAreAboutToImportTasks'),
			showCancelButton: true,
			confirmButtonText: translate('confirm'),
			icon: 'warning',
			showLoaderOnConfirm: true,
			cancelButtonText: translate('cancel'),
			confirmButtonColor: themeConfig.color.primary,
			cancelButtonColor: '#b5bec9',
			preConfirm: async () => {
				asideBuilder.setOpen(false);
				asideBuilder.build();

				try {
					const res = await taskService.postImport(file);

					return {
						success: res?.succeeded,
						message: res?.message === 'ActionFailed' ? null : res.message,
					};
				} catch (e) {
					console.log('Error in import', e);
					return { success: false, message: null };
				}
			},
			allowOutsideClick: () => !Swal.isLoading(),
		}).then((result) => {
			if (result.value?.success) {
				Swal.fire({
					icon: 'success',
					title: translate('successfullyImported'),
					confirmButtonColor: themeConfig.color.primary,
					cancelButtonColor: '#b5bec9',
				}).then(() => window.location.reload());
			} else {
				Swal.fire({
					icon: 'error',
					title: translate('thereWasAProblemWihImportingThisFile'),
					html: `
							<div>
								<p>${result.value?.message || translate('pleaseCheckTheFileAndUseTheProvidedImportTemplate')}<p>
							</div>
						`,
					confirmButtonColor: themeConfig.color.primary,
				});
			}
		});
	};

	const openImport = () => {
		asideBuilder.setTitle(translate('newImport'));
		asideBuilder.setComponent(ImportForm);
		asideBuilder.setComponentKey(`new-import-${new Date()}`);
		asideBuilder.hideSaveButton(true);
		asideBuilder.setComponentProps({
			onImportStart: handleImport,
			activeTaskGroup: activeTaskGroupId,
		});

		asideBuilder.setOpen(true);
		asideBuilder.build();
	};

	useEffect(() => {
		crumbsBuilder.addCurrent('manage');
		crumbsBuilder.build();

		if (taskGroupPermissions.Import) {
			const importAction = actionsBuilder.newAction(translate('import'), 'ri-contacts-book-upload-line');
			importAction.callback = openImport;
			actionsBuilder.addAction(importAction);
			actionsBuilder.build();
		}

		return () => {
			actionsBuilder.reset();
		};
	}, [taskGroupPermissions, activeTaskGroupId]);

	return (
		<div className='w-full h-full flex flex-row' style={{ height: 'calc(100vh - 115px)' }}>
			<TaskGroupSidebar
				toggled={sidebarToggled}
				setToggled={(t) => setSidebarToggled(t)}
				hash={activeTaskGroup}
				tabPath={pathName}
				taskGroups={data}
				permissions={permissionsMap}
				newTaskGroupAction={() => {
					taskGroupPermissions.Create && openCreate();
				}}
				editTaskGroupAction={(taskgroup) => {
					taskGroupPermissions.Update && openEdit(taskgroup);
				}}
				taskGroupPermissions={taskGroupPermissions}
			/>

			<div className='sm:hidden'>
				<ToggleButton onClick={() => setSidebarToggled(true)} />
			</div>
			{activeTaskGroup !== '' ? (
				<div className='p-4 w-full h-full overflow-y-auto'>
					<div>
						{<LinkTabs activeItem={pathName} items={localRoutes} />}
						<div>{routing}</div>
					</div>
				</div>
			) : (
				//NO task group data DIV at the center of the screen
				<div className='w-full h-full flex flex-col items-center justify-center'>
					<div className='text-2xl text-gray-500'>{translate('pleaseInsertTaskGroup')}</div>
				</div>
			)}
		</div>
	);
};
export default withSuspense(withPaddinglessLayout(Manage));
