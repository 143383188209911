import { withDashboardLayout } from "../../hoc";
import { Outlet } from "react-router-dom";

const CoursesModule = () => {
	return <Outlet />;
};

const config = {
	page: "settings",
	pages: [
		{ id: "generalSettings", label: "generalSettings", to: "" },
		// ADD MORE IF NECESSARY { id: "assets/audit", label: "Audit", to: "audit" },
	],
};

export default withDashboardLayout(CoursesModule, config);
