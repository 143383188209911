import Card from 'components/ui/Card';
import { CrudOperations } from 'hoc';
import { useCrud, useTranslations } from 'hooks';
import { runAction } from 'modules/utils';
import { useEffect, useState } from 'react';
import { Filters, Node } from '../components';
import TeamForm from '../forms/team.form';
import TeamService from '../services';

const Tree = ({ permissions }) => {
	const { translate } = useTranslations();
	const service = new TeamService();
	const { create, update, getAll, remove, getExport } = useCrud(service);

	const [data, setData] = useState({ data: [], meta: {} });
	const [showFilters, setShowFilters] = useState(false);
	const [filters, setFilters] = useState({});
	const [queryString, setQueryString] = useState('');
	const [exportInProgress, setExportInProgress] = useState(false);
	const [dispatchingMethods, setDispatchingMethods] = useState([]);

	const fetchAndLoad = async (l_queryString = null, forceLocal = false) => {
		try {
			const qs = forceLocal ? l_queryString : l_queryString || queryString;
			const res = await getAll(qs);
			setData(res);
		} catch (error) {
			console.error('Error on Get All request: ', error);
		}
	};

	const getDispatchingMethods = async () => {
		const enumValues = await runAction('tenants', 'getEnum', 'DispatchingMethod');

		const l_dispatchingMethods = [];
		Object.keys(enumValues).forEach((key) => {
			l_dispatchingMethods.push({
				name: key,
				id: enumValues[key],
			});
		});

		setDispatchingMethods(l_dispatchingMethods);
		return l_dispatchingMethods;
	};

	const { openCreate, openEdit, openView } = CrudOperations({
		create,
		update,
		remove,
		fetchAndLoad,
		service,
		form: TeamForm,
		componentName: 'team',
		permissions: permissions,
		twoStepDeleteMessage: 'HasItemsDependingOnIt',
	});

	const exportData = async () => {
		setExportInProgress(true);
		return await getExport(buildFilterParams(filters), 'teams').then(() => {
			setExportInProgress(false);
		});
	};

	useEffect(() => {
		getDispatchingMethods();
		fetchAndLoad();
	}, []);

	const buildFilterParams = (params = null) => {
		if (!params) return '';
		let queryString = '';
		for (const key in params) {
			if (params[key] !== null) {
				if (Array.isArray(params[key])) {
					queryString += params[key].map((value) => `${key}=${value}`).join('&');
				} else {
					queryString += `${key}=${params[key]}`;
				}
				queryString += '&';
			}
		}
		return queryString.slice(0, -1); // remove the last '&'
	};

	const rowClickHandler = (row, extraProps) => {
		if (permissions.Update) {
			openEdit(row, { ...extraProps, requestReload: fetchAndLoad });
		} else if (permissions.View) {
			openView(row, { ...extraProps, requestReload: fetchAndLoad });
		}
	};

	return (
		<Card
			className='mt-2'
			collapsible
			defaultOpen={true}
			header={'teams'}
			headerButtonLabel={permissions.Create && `+ ${translate('newTeam')}`}
			headerButtonClick={() => openCreate({ requestReload: fetchAndLoad })}
		>
			<div className={'flex md:flex-row flex-col flex-wrap'}>
				<div className='flex-1'></div>
				<div className='flex justify-end items-end'>
					<div className='md:none flex flex-1'></div>
					<div className={`grid ${permissions.Export ? 'grid-cols-2' : 'grid-cols-1'} gap-4`}>
						<div onClick={() => setShowFilters(!showFilters)} className='flex flex-row justify-end'>
							<div className='cursor-pointer py-2 flex flex-row items-center opacity-75 hover:opacity-100'>
								<i className='ri-filter-3-line'></i>
								<div className='ml-1 text-xs font-bold'>{translate('filter')}</div>
							</div>
						</div>
						{permissions.Export && (
							<div
								onClick={() => {
									exportData();
								}}
								className='flex flex-row justify-end'
							>
								<div className='cursor-pointer py-2 flex flex-row items-center justify-end opacity-75 hover:opacity-100'>
									{exportInProgress ? (
										<>
											<i className='ri-loader-2-line animate-spin'></i>
											<div className='ml-1 text-xs font-bold'>{translate('progressing')}...</div>
										</>
									) : (
										<>
											<i className='ri-file-chart-line'></i>
											<div className='ml-1 text-xs font-bold'>{translate('export')}</div>
											<i className='ml-2 ri-arrow-down-s-line'></i>
										</>
									)}
								</div>
							</div>
						)}
					</div>
				</div>
			</div>

			{showFilters ? (
				<Filters
					onChange={(data) => {
						setFilters(data);
						const filterParams = buildFilterParams(data);

						fetchAndLoad(filterParams, true);
						setQueryString(filterParams);
					}}
				/>
			) : (
				<></>
			)}
			{data.data.map((item) => {
				return (
					<Node
						id={item.id}
						key={item.id}
						name={item.name}
						level={0}
						dispatchingMethod={item.dispatchingMethod}
						dispatchingMethods={dispatchingMethods}
						taskGroups={item.taskGroups}
						color={item.color}
						children={item.children}
						onClick={() => rowClickHandler(item)}
						extraProps={{ requestReload: fetchAndLoad }}
						openEditTeam={(item, extraProps) => rowClickHandler(item, extraProps)}
					/>
				);
			})}
			{data.data.length === 0 && (
				<div className='h-28  flex flex-col items-center justify-center'>
					<div className='text-md text-gray-500'>{translate('noDataAvailable')}</div>
				</div>
			)}
		</Card>
	);
};

export default Tree;
