import { withSuspense } from 'hoc';
import { useCallback, useEffect, useRef, useState } from 'react';

import { useToolbar } from 'hooks';
import usePermissions, { PERMISSION, RESOURCE } from 'hooks/usePermissions';
import TaskTableRefreshContext from './Contexts/index';
import OnlineEmployees from './pages/Employee/parts/EmployeeTable';
import TasksTable from './pages/Tasks/parts/TasksTable';
import TeamsTable from './pages/Teams/parts/TeamTable';

const TasksPage = () => {
	const { crumbsBuilder } = useToolbar();
	const [taskControls, setTaskControls] = useState({});
	const onlineEmployeesRef = useRef(null);
	const teamsRef = useRef(null);

	const { hasPermission } = usePermissions();
	const permissionsMap = {
		tasks: {
			Create: hasPermission(RESOURCE.TaskItem, PERMISSION.Create),
			View: hasPermission(RESOURCE.TaskItem, PERMISSION.View),
			Update: hasPermission(RESOURCE.TaskItem, PERMISSION.Create),
			Delete: hasPermission(RESOURCE.TaskItem, PERMISSION.Delete),
			Export: hasPermission(RESOURCE.TaskItem, PERMISSION.Export),
			Allocate: hasPermission(RESOURCE.TaskItem, PERMISSION.Allocate),
		},
		employees: {
			Create: hasPermission(RESOURCE.Employee, PERMISSION.Create),
			View: hasPermission(RESOURCE.Employee, PERMISSION.View),
			Update: hasPermission(RESOURCE.Employee, PERMISSION.Create),
			Delete: hasPermission(RESOURCE.Employee, PERMISSION.Delete),
			Export: hasPermission(RESOURCE.Employee, PERMISSION.Export),
		},
		teams: {
			Create: hasPermission(RESOURCE.Team, PERMISSION.Create),
			View: hasPermission(RESOURCE.Team, PERMISSION.View),
			Update: hasPermission(RESOURCE.Team, PERMISSION.Create),
			Delete: hasPermission(RESOURCE.Team, PERMISSION.Delete),
			Export: hasPermission(RESOURCE.Team, PERMISSION.Export),
		},
	};

	useEffect(() => {
		crumbsBuilder.addCurrent('tasks');
		crumbsBuilder.build();
	}, []);

	const refreshHandler = useCallback(() => {
		onlineEmployeesRef.current?.refresh();
		teamsRef.current?.refresh();
	}, []);

	return (
		<TaskTableRefreshContext.Provider value={refreshHandler}>
			<div className='grid grid-cols-1 gap-2'>
				<TasksTable
					permissions={permissionsMap.tasks}
					onControlUpdate={(controls) => {
						setTaskControls(controls);
					}}
					onRefresh={refreshHandler}
				/>

				<OnlineEmployees ref={onlineEmployeesRef} permissions={permissionsMap.employees} />
				<TeamsTable ref={teamsRef} permissions={permissionsMap.teams} controls={taskControls} />
			</div>
		</TaskTableRefreshContext.Provider>
	);
};

export default withSuspense(TasksPage);
