import { useRef, useState } from "react";
import { Button } from "components/ui/Input";

const HelperAside = ({
	open = false,
	right = false,
	title = "Menu",
	onClose = () => {},
	Component = null,
	componentKey = "",
	componentProps = {},
	hideSaveButton = true,
	saveCallback = (data) => {},
}) => {
	const [loading, setLoading] = useState(false);
	const componentRef = useRef(null);

	const classes = {
		default: right
			? "right-0 md:right-[32rem] border-gray-200 border-l"
			: "left-0 md:left-[32rem] border-gray-200 border-r",
		close: right ? "translate-x-full" : "-translate-x-[64rem] -ml-20",
	};

	const _doSubmitFromButton = async () => {
		if (componentRef.current?.runSubmit) {
			componentRef.current?.runSubmit();
		} else {
			_doSubmit();
		}
	};

	const _doSubmit = async (formData = null) => {
		setLoading(true);
		const data = formData || componentRef.current?.getData();
		if (data) {
			await saveCallback(data);
			onClose();
			componentRef.current?.clear();
		}
		setLoading(false);
	};

	return (
		<>
			{/* Main Side Menu */}
			<div
				className={`${open ? "" : classes.close} absolute top-0 ${
					classes.default
				} bottom-0 z-50 md:z-30 w-full sm:w-[32rem] p-4 pr-2 h-full flex flex-col transition-transform ease-in-out duration-300 bg-white`}
			>
				<div className="flex flex-row justify-between pr-2">
					<div className="flex flex-row items-center">
						<button
							type="button"
							onClick={onClose}
							className="text-gray-400 bg-transparent -ml-1 mr-1 hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 inline-flex items-center"
						>
							<i className="ri-close-line text-lg"></i>
							<span className="sr-only">Close menu</span>
						</button>
						<h5
							id="drawer-navigation-label"
							className="text-base font-semibold text-gray-500 uppercase"
						>
							{title}
						</h5>
					</div>
				</div>

				<div className="py-4 h-full overflow-y-auto  pr-2">
					{Component && (
						<Component
							componentOpen={open}
							key={componentKey}
							ref={componentRef}
							{...componentProps}
						/>
					)}
				</div>

				{!hideSaveButton && (
					<div className="py-1 flex flex-row-reverse justify-between items-center">
						<Button
							disabled={loading}
							onClick={_doSubmitFromButton}
						>
							Save
						</Button>
					</div>
				)}
			</div>
		</>
	);
};

export default HelperAside;
