import React, { useState, useEffect } from "react";
import { Select, Input, ReSelect, MultipleSelect } from "components/ui/Input";

import { useTranslations } from "hooks";
import DepartmentService from "modules/persons/pages/Users/pages/TeamsAndDepartments/Departments/services";
import TaskTypeService from "modules/tasks/pages/Manage/pages/TaskTypes/services";
import TaskGroupService from "modules/tasks/pages/Manage/services";
const Filters = ({ onChange = () => {} }) => {
	const { translate } = useTranslations();
	const departmentService = new DepartmentService();
	const taskTypeService = new TaskTypeService();
	const taskGroupService = new TaskGroupService();

	const [taskGroupOptions, setTaskGroupOptions] = useState([]);
	const [taskTypesOptions, setTaskTypesOptions] = useState([]);
	const [taskGroups, setTaskGroups] = useState([]);
	const [taskTypes, setTaskTypes] = useState([]);
	const [department, setDepartment] = useState(null);
	const [team, setTeam] = useState(null);

	useEffect(() => {
		taskGroupService.getOptionsList().then((res) => {
			setTaskGroupOptions(res.data);
		});

		fetchTaskTypes(taskGroups);
	}, []);

	const fetchTaskTypes = (taskGroups = []) => {
		let taskGroupIds = [];
		if (taskGroups && taskGroups.some((item) => item === 1)) {
			const options = taskGroupOptions?.map((item) => item.value) ?? [];
			taskGroupIds = options.filter((item) => item !== 1);
		} else {
			taskGroupIds = taskGroups?.map((item) => item) ?? [];
		}

		taskTypeService.getMultiParentOptionsList(taskGroupIds).then((res) => {
			setTaskTypesOptions(res);
		});
	};

	useEffect(() => {
		const request = prepareRequest();
		onChange(request);
	}, [department, team, taskGroups, taskTypes]);

	const prepareRequest = () => {
		const request = {};
		if (department && department !== "-1") {
			request.DepartmentId = department;
		} else {
			request.DepartmentId = null;
		}

		if (team && team.length > 2) {
			request.Name = team;
		} else {
			request.Name = null;
		}

		if (taskGroups.length > 0) {
			request.TaskGroupIds = taskGroups;
		} else {
			request.TaskGroupIds = null;
		}

		if (taskTypes.length > 0) {
			request.TaskTypeIds = taskTypes;
		} else {
			request.TaskTypeIds = null;
		}
		return request;
	};

	return (
		<div className="ml-3 grid grid-cols-1 gap-4 sm: md:grid-cols-4 w-full items-center ">
			<div>
				<div className="h-4 text-xs font-semibold text-slate-500">
					{translate("team")}
				</div>
				<div className="font-small text-md">
					<Input
						type="text"
						placeholder="searchByTeamName"
						onChange={(e) => setTeam(e.target.value)}
						value={team}
					/>
				</div>
			</div>
			{/* <div>
				<div className="h-4 text-xs font-semibold text-slate-500">
					{translate("department")}
				</div>
				<div className="font-small text-md">
					<ReSelect
						service={departmentService}
						onChange={(value) => {
							if (!value) value = "-1";
							setDepartment(value);
						}}
					/>
				</div>
			</div> */}
			<div>
				{/* //Task Group */}
				<div className="h-4 text-xs font-semibold text-slate-500">
					{translate("taskGroup")}
				</div>
				<div className="font-small text-md">
					<MultipleSelect
						options={taskGroupOptions}
						onSelectOption={(option) => {
							setTaskGroups(option);
							fetchTaskTypes(option);
						}}
						value={taskGroups}
					/>
				</div>
			</div>
			{/* <div>
				<div className="h-4 text-xs font-semibold text-slate-500">
					{translate("excludedTaskTypes")}
				</div>
				<div className="font-small text-md">
					<MultipleSelect
						name="taskTypeIds"
						options={taskTypesOptions}
						onSelectOption={setTaskTypes}
					/>
				</div>
			</div> */}
		</div>
	);
};

export default Filters;
