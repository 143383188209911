import CustomFieldTable from '../pages/CustomFields/parts/CustomFieldTable';

import BlockAction from '../pages/ComplexActions/pages/BlockAction/parts/ActionTable';
import CancelAction from '../pages/ComplexActions/pages/CancelAction/parts/ActionTable';
import CancelAndCopyAction from '../pages/ComplexActions/pages/CancelAndCopy/parts/ActionTable';
import CancelAndReturnAction from '../pages/ComplexActions/pages/CancelAndReturn/parts/ActionTable';
import CompleteAndCopyAction from '../pages/ComplexActions/pages/CompleteAndCopy/parts/ActionTable';
import CompleteAndReturnAction from '../pages/ComplexActions/pages/CompleteAndReturn/parts/ActionTable';
import RefuseAction from '../pages/ComplexActions/pages/Refuse/parts/ActionTable';
import ReturnAction from '../pages/ComplexActions/pages/Return/parts/ActionTable';

const routes = [
	{
		header: 'general',
		key: 'General',
		items: [
			// {
			// 	name: "overview",
			// 	key: "Overview",
			// 	hash: "#overview",
			// 	component: Overview,
			// },
			{
				name: 'customFields',
				key: 'CustomFields',
				hash: '#custom-fields',
				component: CustomFieldTable,
			},
		],
	},

	{
		header: 'complexActions',
		key: 'ComplexActions',
		items: [
			{
				name: 'cancelAction',
				key: 'CancelAction',
				hash: '#cancel-action',
				component: CancelAction,
			},
			{
				name: 'blockAction',
				key: 'BlockAction',
				hash: '#block-action',
				component: BlockAction,
			},
			{
				name: 'returnAction',
				key: 'ReturnAction',
				hash: '#return-action',
				component: ReturnAction,
			},
			{
				name: 'cancelAndCopyAction',
				key: 'CancelAndCopyAction',
				hash: '#cancel-and-copy-action',
				component: CancelAndCopyAction,
			},
			{
				name: 'cancelAndReturnAction',
				key: 'CancelAndReturnAction',
				hash: '#cancel-and-return-action',
				component: CancelAndReturnAction,
			},
			{
				name: 'completeAndCopyAction',
				key: 'CompleteAndCopyAction',
				hash: '#complete-and-copy-action',
				component: CompleteAndCopyAction,
			},
			{
				name: 'completeAndReturnAction',
				key: 'CompleteAndReturnAction',
				hash: '#complete-and-return-action',
				component: CompleteAndReturnAction,
			},
			{
				name: 'refuseAction',
				key: 'RefuseAction',
				hash: '#refuse-action',
				component: RefuseAction,
			},
		],
	},
];
export default routes;
