import { forwardRef } from "react";

const Form = forwardRef(({ children, onSubmit = () => { } }, ref) => {

	return (
		<form ref={ref} onSubmit={onSubmit}>
			<table className="w-full align-top">
				<tbody>{children}</tbody>
			</table>
		</form>
	);
});

export default Form;