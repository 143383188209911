import AbstractService from 'services/Service';
import { extractMeta } from 'utils/pagination';

const endpoint = 'tasks';

class Service extends AbstractService {
	constructor() {
		super();
		this.endpoint = endpoint;
	}

	async search(queryString, params) {
		const result = await this.api.post(`${this.endpoint}/search?${queryString}`, params);
		return {
			data: result.data,
			meta: extractMeta(result, queryString),
		};
	}

	async getActions() {
		const result = await this.api.get(`${this.endpoint}/actions`);
		return result.data;
	}

	async getTaskStatuses() {
		const result = await this.api.get(`${this.endpoint}/statuses/options-list`);
		return result.data;
	}

	async changeStatus(ids, act) {
		const result = await this.api.put(`${this.endpoint}/status/${act}`, {
			ids: ids,
		});
		return result;
	}

	async getMultipleCopyFields(id) {
		const result = await this.api.get(`${this.endpoint}/${id}/multiple-copy/fields`);
		return result.data;
	}

	async allocate(ids, employeeId) {
		const result = await this.api.put(`${this.endpoint}/allocate/${employeeId}`, {
			ids: ids,
		});
		return result;
	}

	async performAction(id, act, body = {}) {
		const result = await this.api.post(`${this.endpoint}/${id}/action/${act}`, { ...body });
		return result;
	}

	async sendToNextLocation(id, nextTaskId) {
		const result = await this.api.post(`${this.endpoint}/${id}/send-to/${nextTaskId}`);
		return result;
	}

	async getQuick(id) {
		const result = await this.api.get(`${this.endpoint}/${id}/quick`);
		return result;
	}

	async blockTask(id, minutes) {
		const result = await this.api.put(`${this.endpoint}/${id}/block/${minutes}`);
		return result;
	}

	async createMultipleCopy(id, data, createAnyWay = false) {
		const mappedData = this.#mapMultipleCopyData(data, createAnyWay);

		const result = await this.api.post(`${this.endpoint}/${id}/multiple-copy`, { requests: mappedData });
		return result;
	}

	async fieldGating(data) {
		return await this.api.post(`${this.endpoint}/default-settings`, await this.mapGatingData(data));
	}

	async mapGatingData(data) {
		const { taskGroupId, taskTemplateId, fields } = await this.mapData(data, false);

		return {
			taskGroupId,
			taskTemplateId,
			fields: fields
				.filter((f) => f.triggerDefaultSettings)
				.map((f) => {
					const { triggerDefaultSettings, ...rest } = f;
					return rest;
				}),
		};
	}

	#mapMultipleCopyData(data, createAnyWay = false) {
		let request = [];
		const fieldGroups = this.destructObjectSubmit2D(data, 'fields');

		fieldGroups.forEach((fieldGroup) => {
			fieldGroup.forEach((field) => {
				Object.keys(field).forEach((key) => {
					field[key] = this.parseValue(field[key]);
				});

				if (field.value === undefined || field.value === '') {
					field.value = null;
				} else {
					if (field.inputType === 16) {
						//if it is address input
						const address = JSON.parse(field.value);
						if (address) {
							field.value = {
								Level: address.level,
								Id: address.id,
							};
						}
					}

					//IF multiple select, or checkGroup explode the value and parse it
					if (field.inputType === 12 || field.inputType === 9) {
						field.value = JSON.stringify(field.value?.split(','));
					} else if (field.value !== null) {
						field.value = JSON.stringify([field.value]);
					} else field.value = null;
				}

				delete field.inputType;
				return field;
			});
			request.push({
				fields: fieldGroup.filter((item) => item !== null),
				createAnyWay: createAnyWay,
			});
		});

		return request;
	}

	async getReturnActions(queryString, id) {
		const result = await this.api.get(`${this.endpoint}/${id}/return?${queryString}`);

		return {
			data: result.data,
			meta: extractMeta(result, queryString),
		};
	}

	async postExport(queryString, params) {
		const result = await this.api.post(`${this.endpoint}/export?${queryString}`, params, {
			responseType: 'blob',
		});
		return result;
	}

	mapData(data, removeTriggerDefaultSettings = true) {
		const { taskTemplateId, taskGroupId, createAnyWay, ...rest } = data;

		const fields = this.destructObjectSubmit(rest, 'fields');

		fields.forEach((field) => {
			Object.keys(field).forEach((key) => {
				field[key] = this.parseValue(field[key]);
			});

			if (field.value === undefined || field.value === '') {
				field.value = null;
			} else {
				if (field.inputType === 16) {
					//if it is address input
					const address = JSON.parse(field.value);
					if (address) {
						field.value = {
							Level: address.level,
							Id: address.id,
						};
					}
				}

				//IF multiple select, or checkGroup explode the value and parse it
				if (field.inputType === 12 || field.inputType === 9) {
					field.value = JSON.stringify(field.value?.split(','));
				} else if (field.value !== null) {
					field.value = JSON.stringify([field.value]);
				} else field.value = null;
			}

			delete field.inputType;
			return field;
		});

		return {
			taskTemplateId: this.parseValue(taskTemplateId),
			taskGroupId: this.parseValue(taskGroupId),
			fields: fields
				.filter((item) => item !== null)
				.map((f) => {
					if (removeTriggerDefaultSettings) {
						const { triggerDefaultSettings, ...rest } = f;
						return rest;
					} else {
						return f;
					}
				}),
			createAnyWay: createAnyWay || false,
		};
	}

	async postImport(file) {
		const form = new FormData();
		form.append('file', file);
		return await this.api.postForm(`${this.endpoint}/import`, form);
	}
}
export default Service;
