import { CheckBox } from 'components/ui/Input';
import { useTranslations } from 'hooks';
import { Suspense, useEffect, useState } from 'react';

const Permission = ({
	permissionKey,
	index,
	permissions,
	activePermissions,
	disabledAndCheckedPermissions,
	onFormDirty = () => {},
	isView = false,
}) => {
	const [checkboxes, setCheckboxes] = useState({});
	const { translate } = useTranslations();

	const isKeyDisabled = (permissionKey) => {
		return checkboxes[permissionKey] ? checkboxes[permissionKey]['disabled'] : false;
	};

	const updateCheckboxState = (keys, checked) => {
		setCheckboxes((prevState) => {
			const newCheckboxes = { ...prevState };
			keys.forEach((key) => {
				if (!isKeyDisabled(key)) {
					newCheckboxes[key] = { checked, disabled: false };
				}
			});
			return newCheckboxes;
		});
	};

	const handleCheckboxChange = (event) => {
		onFormDirty();
		const [permissionKey, permissionValue] = event.target.name.split('__');
		if (
			(permissionKey.includes('TaskAction') && !permissionKey.includes('ComplexTaskAction')) ||
			permissionValue === undefined
		)
			return;

		const isChecked = event.target.checked;
		const baseKeys = [`${permissionKey}__1`];
		if (isChecked) {
			switch (permissionValue) {
				case '2':
					updateCheckboxState([...baseKeys, `${permissionKey}__2`], true);
					break;
				case '4':
					updateCheckboxState([...baseKeys, `${permissionKey}__2`, `${permissionKey}__4`], true);
					break;
				case '16':
					updateCheckboxState([...baseKeys, `${permissionKey}__16`], true);
					break;
				default:
					return;
			}
		} else {
			switch (permissionValue) {
				case '1':
					updateCheckboxState(
						[`${permissionKey}__1`, `${permissionKey}__2`, `${permissionKey}__4`, `${permissionKey}__16`],
						false,
					);
					break;
				case '2':
					updateCheckboxState([`${permissionKey}__2`, `${permissionKey}__4`], false);
					break;
				default:
					return;
			}
		}
	};

	const updateCheckboxes = (permissionsValue, isDisabled) => {
		if (!permissionsValue) return {};

		const permissionsValueBinary = permissionsValue.toString(2);
		const permissionsValueBinaryArray = permissionsValueBinary.split('').reverse();

		let newCheckboxes = {};
		Object.keys(permissions[permissionKey]).map((permissionItemKey, permissionIndex) => {
			const permissionValue = permissions[permissionKey][permissionItemKey];
			const keyName = permissionKey + '__' + permissionValue;
			newCheckboxes[keyName] = {
				checked: permissionsValueBinaryArray[permissionIndex] === '1',
				disabled: isDisabled ? permissionsValueBinaryArray[permissionIndex] === '1' : false,
			};
		});

		return newCheckboxes;
	};

	useEffect(() => {
		const activePermissionCheckboxes = updateCheckboxes(activePermissions, false);
		const newCheckboxes = updateCheckboxes(disabledAndCheckedPermissions, true);

		Object.keys(activePermissionCheckboxes).forEach((customPermissionKey) => {
			if (!newCheckboxes[customPermissionKey] || !newCheckboxes[customPermissionKey]['disabled'])
				newCheckboxes[customPermissionKey] = activePermissionCheckboxes[customPermissionKey];
		});

		setCheckboxes(newCheckboxes);
	}, [disabledAndCheckedPermissions, activePermissions]);

	return (
		<Suspense fallback={<div>Loading...</div>}>
			<div className='pl-2 py-2 border-b border-gray-200 ' key={index}>
				<div className='grid grid-cols-4 items-center gap-5'>
					<div>
						<p className='text-sm font-semibold text-gray-900'>{translate(permissionKey)}</p>
					</div>
					<div className=''>
						<div className='grid grid-flow-col auto-cols-max gap-4'>
							{Object.keys(permissions[permissionKey]).map((permissionItemKey, permissionIndex) => {
								const permissionValue = permissions[permissionKey][permissionItemKey];
								const keyName = permissionKey + '__' + permissionValue;
								return (
									<div key={permissionIndex}>
										<CheckBox
											isView={isView}
											checkboxSize='text-xl'
											labelOnNewLine
											label={translate(permissionItemKey)}
											name={keyName}
											selected={checkboxes[keyName] ? checkboxes[keyName]['checked'] : false}
											disabled={checkboxes[keyName] ? checkboxes[keyName]['disabled'] : false}
											onChange={handleCheckboxChange}
										/>
									</div>
								);
							})}
						</div>
					</div>
				</div>
			</div>
		</Suspense>
	);
};
export default Permission;
